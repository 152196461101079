import { Component } from '@angular/core';
import { Globals } from './globals';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(public globals: Globals, private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title) { }

  ngOnInit(): void {

    const body = document.querySelector('body');
    body.style.setProperty('--screen-height', $(window).height() + "px");

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      const rt = this.getChild(this.activatedRoute);
      rt.data.subscribe(data => {
        this.titleService.setTitle('Administration | ' + data.title)
      });
    });
  }




  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.adjustWrapContentHeight();

      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd)
        )
        .subscribe((event: NavigationEnd) => {
          this.adjustWrapContentHeight();
        });

    }, 2000)
  }

  adjustWrapContentHeight(): void {
    if ($(window).width() < 3000) {
      const headerHeight = $('header').outerHeight() || 0;
      const navHeight = $('.my_products_block').outerHeight() || 0;
      const bredcrumbHeight = $('.breadcrumb_block').outerHeight() || 0;
      const footerHeight = $('footer').outerHeight() || 0;
      const windowHeight = $(window).height() || 0;
      const wrapContentHeight = windowHeight - headerHeight - navHeight - bredcrumbHeight - footerHeight;
      $('.inner_content_block').css('min-height', `${wrapContentHeight}px`);
    } else {
      $('.inner_content_block').css('min-height', 'auto');
    }
  }

}
