import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { Globals } from '.././globals';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
declare var $: any;

@Injectable()
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
    public globals: Globals,
    private CookieService: CookieService
  ) { }

  canActivate(route, state: RouterStateSnapshot) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (screen.width < 992) {
      $('.sidebar_block').removeClass('active');
      $('.sidebar_block').removeClass('small_menu');
    }
    if (screen.width < 1200) {
      $('.sidebar_block').addClass('small_menu');
      $('.sidebar_block .expand_collapse_block button i').addClass(
        'fa-angle-left'
      );
      $('.sidebar_block .expand_collapse_block button i').removeClass(
        'fa-angle-right'
      );
      $('.right_wrapper').addClass('full_right_block');
    }

    if (state.url.split('/')[4] != undefined) {
      this.globals.currentLink =
        '/' +
        state.url.split('/')[1] +
        '/' +
        state.url.split('/')[2] +
        '/' +
        state.url.split('/')[3] +
        '/' +
        state.url.split('/')[4];
      this.globals.currentModule = state.url.split('/')[1];
    } else if (state.url.split('/')[3] != undefined) {
      this.globals.currentLink =
        '/' +
        state.url.split('/')[1] +
        '/' +
        state.url.split('/')[2] +
        '/' +
        state.url.split('/')[3];
      this.globals.currentModule = state.url.split('/')[1];
    } else if (state.url.split('/')[2] != undefined) {
      this.globals.currentLink =
        '/' + state.url.split('/')[1] + '/' + state.url.split('/')[2];
      this.globals.currentModule = state.url.split('/')[1];
    } else {
      this.globals.currentLink = '/' + state.url.split('/')[1];
      this.globals.currentModule = state.url.split('/')[1];
    }


    if (
      state.url == '/landing-dashboard' ||
      ((state.url.split('/')[1]).split('?')[0]) == 'authentication'
    ) {
      this.globals.isLoading = true;
    }

    if (
      state.url != '/landing-dashboard' &&
      ((state.url.split('/')[1]).split('?')[0]) != 'authentication' &&
      !this.CookieService.get('product_permission')
    ) {
      this.router.navigate(['/landing-dashboard']);
      return false;
    }
    
    if (this.authService.isLoggedIn() == true) {
    
      if (
        this.globals.authData != null &&
        this.globals.authData.id == this.CookieService.get('user_token')
      ) {
      
       
        if (this.globals.institute_details != null) {

            if (
            (state.url.split('/')[2] == 'dashboard' || state.url.split('/')[2] == 'admin-dashboard' )&&
            this.globals.institute_details &&
            this.globals.institute_details.role_value == 6
          ) {
            this.router.navigate(['/sat/assessment/list']);
            return false;
          }


          if (
            this.globals.institute_details.role_value == 4 ||
            this.globals.institute_details.role_value == 5
          ) {
            this.globals.institute_details = null;
           
            this.globals.authData = '';
            this.CookieService.delete('auth_user','/', this.globals.CookieDomainUrl);

          } else {
            let instituteList_new = [];
            instituteList_new = this.globals.authData.list.filter(
              (s) =>
                s.role_value == this.globals.institute_details.role_value &&
                ((s.institute != '' &&
                  this.globals.institute_details.institute != '' &&
                  s.institute.id ==
                  this.globals.institute_details.institute.id) ||
                  1 == 1)
            );
            let institute_details =
              instituteList_new.length > 0 ? instituteList_new[0] : null;
            if (institute_details == null || this.CookieService.get('refresh_authdata') == '1') {
              this.CookieService.delete('refresh_authdata', '/', this.globals.CookieDomainUrl);
              this.globals.institute_details = institute_details;
              this.CookieService.delete(
                'institute_details',
                '/',
                this.globals.CookieDomainUrl
              );
              this.globals.authData = '';
              this.CookieService.delete('auth_user','/', this.globals.CookieDomainUrl);
            }
          }
        }
        return this.setConfigData(route, state);
      } else {
        this.authService.getAuthUser().then(
          (data) => {
          
            this.router.navigate([state.url]);
            return false;
          },
          (error) => {
            this.globals.isLoading = false;
            this.globals.errorSweetAlert();
          }
        );
      }
    } else {
      this.globals.IsLoggedIn = false;
      if (
        ((state.url.split('/')[1]).split('?')[0]) == 'authentication' ||
        state.url == '/admin/login'
      ) {
        return true;
      } else {
        window.location.href = this.globals.amsnLoginUrl;
        return false;
      }
    }
  }

  setConfigData(route, state) {  
    this.globals.IsLoggedIn = true;
    if (
      ((state.url.split('/')[1]).split('?')[0]) == 'authentication' ||
      state.url == '/admin/login' ||
      state.url == '/register' ||
      state.url == '/forgot-password' ||
      state.url.split('/')[1] == 'reset-password'
    ) {
      this.router.navigate(['/dashboard']);
      return false;
    } else {
      if (
        this.globals.institute_details &&
        (this.globals.institute_details.role_value == 4 ||
          this.globals.institute_details.role_value == 5) &&
        state.url == '/dashboard'
      ) {
        this.router.navigate(['/assessment/my-assessments']);
        return false;
      }

      if (
        this.globals.institute_details == null &&
        state.url.split('/')[1] != 'landing-dashboard'
      ) {
        window.location.href = this.globals.authUrl + '/landing-dashboard';
        return false;
      } else {

        if (
          this.globals.institute_details &&
          this.globals.institute_details.role_value != 1 &&
          route.data['permission'] != undefined
        ) {
          if (
            localStorage.getItem('last_updated_date_role_permission') &&
            localStorage.getItem('user_role_permission')
          ) {
            this.authService.getLastUpdatedDateOfRolePermission().then(
              (res: any) => {
                let lastUpdatedDateRolePermission = new Date(
                  res['data'].updated_at
                );
                let cookie_date = new Date(
                  window.atob(
                    localStorage.getItem('last_updated_date_role_permission')
                  )
                );
                if (lastUpdatedDateRolePermission <= cookie_date) {
                  if (
                    window.atob(this.CookieService.get('current_role_value')) ==
                    this.globals.institute_details.role_value
                  ) {
                    let data = JSON.parse(
                      window.atob(localStorage.getItem('user_role_permission'))
                    );
                    this.globals.permissions = data;
                  } else {
                    this.CookieService.set(
                      'current_role_value',
                      window.btoa(this.globals.institute_details.role_value),
                      365,
                      '/',
                      this.globals.CookieDomainUrl
                    );
                    let permission_data = JSON.parse(
                      window.atob(localStorage.getItem('all_role_permission'))
                    );
                    let active_license =
                      this.globals.institute_details.active_license > 0 ? 0 : 0;
                    let retrievedObject1 = permission_data.filter((item) => {
                      if (
                        item.role_value ==
                        this.globals.institute_details.role_value &&
                        item.is_accessible_after_expire == active_license
                      ) {
                        return item;
                      }
                    })[0].permission_list;

                    let user_role_permission = {};
                    let index;
                    this.globals.permissionText.forEach(function (menu, key) {
                      index = retrievedObject1.findIndex(
                        (retrievedObject1) =>
                          retrievedObject1.slug === menu.key &&
                          retrievedObject1.has_access == 1
                      );
                      if (index != -1) {
                        user_role_permission[menu.key] = true;
                      } else {
                        user_role_permission[menu.key] = false;
                      }
                    });
                    localStorage.setItem(
                      'user_role_permission',
                      window.btoa(JSON.stringify(user_role_permission))
                    );
                    this.globals.permissions = user_role_permission;
                  }
                  if (!this.globals.permissions[route.data['permission']]) {
                    this.router.navigate(['/permission-not-found']);
                    return false;
                  }
                } else {
                  this.authService.getAllRolePermissionData(
                    route.data['permission']
                  );
                }
              },
              (error) => {
                this.globals.isLoading = false;
                this.globals.errorSweetAlert();
              }
            );
          } else {
            this.authService.getAllRolePermissionData(route.data['permission']);
          }
        } else if (
          this.globals.institute_details &&
          (this.globals.institute_details.role_value == 1 
          )
        ) {

          let user_role_permission = {};
          this.globals.permissionText.forEach(function (menu, key) {
            user_role_permission[menu.key] = true;
          });
          localStorage.setItem(
            'user_role_permission',
            window.btoa(JSON.stringify(user_role_permission))
          );
          this.globals.permissions = user_role_permission;
        }
        if (
          this.globals.institute_details != null &&
          state.url.split('/')[1] != 'landing-dashboard' &&
          state.url != '/permission-not-found' &&
          state.url != '/page-not-found'
        ) {

          if (
            this.globals.ProductId == this.globals.ProductIdArray[1] &&
            state.url.split('/')[1] != 'covid'
          ) {
            this.router.navigate(['/covid']);
          } else if (
            this.globals.ProductId == this.globals.ProductIdArray[2] &&
            state.url.split('/')[1] != 'sat'
          ) {
            this.router.navigate(['/sat']);
          }
        }
        let data = localStorage.getItem('user_role_permission')
          ? JSON.parse(
            window.atob(localStorage.getItem('user_role_permission'))
          )
          : null;
        return true;
      }
      
    }
  }
}
