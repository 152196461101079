<div class="inner_content_block">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <div class="title_block">
                    <h1 class="float-start">
                        <ng-template [ngIf]="id==0" [ngIfElse]="updateTitle"> Add {{itemText.titlecase_with_article}}
                        </ng-template>
                        <ng-template #updateTitle> Update {{itemText.titlecase_with_article}} </ng-template>
                    </h1>
                    <a kendoButton
                        routerLink="/{{this.globals.currentLink.split('/')[1]}}/configuration/knowledge-statement/list"
                        class="all_btn themeoption_btn float-end" *ngIf="globals.permissions['question-list']">Back to
                        {{itemText.titlecase_plural}}</a>
                    
                    <div class="clearfix"></div>
                </div>
                
                <form class="k-form" [formGroup]="questionForm">
                    <fieldset class="k-form-fieldset">
                        <div class="grey_form_block">
                            <h4 *ngIf="id != 0">{{itemText.titlecase}} ID: <span
                                    class="id_box mb-0">{{questionForm.controls.question_provider_id.value}}</span></h4>
                            <div class="row">
                                <div class="col-12 col-xl-6 col-lg-6 col-md-6">
                                    <kendo-formfield>
                                        <label><span>*</span> Domain</label>
                                        <kendo-dropdownlist #domain_id formControlName="domain_id" [data]="domainList"
                                            [textField]="'name'" [valueField]="'id'" [valuePrimitive]="true"
                                            [filterable]="true" (filterChange)="domainFilter($event)"
                                            (valueChange)="getSubdomainsByDomian($event)">
                                            <ng-template kendoMultiSelectNoDataTemplate>
                                                <h6>NO DOMAIN FOUND</h6>
                                            </ng-template>
                                        </kendo-dropdownlist>
                                        <kendo-formerror *ngIf="questionForm.controls.domain_id.errors?.required">
                                            Please select domain.
                                        </kendo-formerror>
                                    </kendo-formfield>
                                </div>
                                <div class="col-12 col-xl-6 col-lg-6 col-md-6">
                                    <kendo-formfield>
                                        <label><span>*</span>Subdomain</label>
                                        <kendo-dropdownlist #subdomain_id formControlName="subdomain_id"
                                            [data]="subdomainList" [textField]="'name'" [valueField]="'id'"
                                            [valuePrimitive]="true" [filterable]="true"
                                            (filterChange)="subdomainFilter($event)">
                                            <ng-template kendoMultiSelectNoDataTemplate>
                                                <h6>NO SUBDOMAIN FOUND</h6>
                                            </ng-template>

                                            <ng-template kendoMultiSelectNoDataTemplate>
                                                <h6>Select a Domain First</h6>
                                            </ng-template>
                                        </kendo-dropdownlist>
                                        <kendo-formerror *ngIf="questionForm.controls.subdomain_id.errors?.required">
                                            Please select subdomain.
                                        </kendo-formerror>
                                    </kendo-formfield>
                                </div>
                               
                                <div class="col-12 col-xl-6 col-lg-6 col-md-6" *ngIf="globals.currentModule == 'sat'">
                                    <kendo-formfield>
                                        <label><span>*</span>Competency</label>
                                        <kendo-textbox #competency formControlName="competency"></kendo-textbox>
                                        <kendo-formerror *ngIf="questionForm.controls.competency.errors?.required">
                                            Please enter competency.
                                        </kendo-formerror>
                                    </kendo-formfield>
                                </div>
                                <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                                    <kendo-formfield>
                                        <label><span>*</span> {{itemText.titlecase}}</label>
                                        <kendo-editor formControlName="question_text" class="editor_style">
                                            <kendo-toolbar>
                                                
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorItalicButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorUnderlineButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorStrikethroughButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorAlignLeftButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignCenterButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignRightButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignJustifyButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorInsertOrderedListButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorIndentButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorOutdentButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorCreateLinkButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorUnlinkButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                                                <kendo-toolbar-button kendoEditorViewSourceButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar>
                                        </kendo-editor>
                                        <kendo-formerror *ngIf="questionForm.controls.question_text.errors?.required">
                                            Please enter {{itemText.lowercase}} text.
                                        </kendo-formerror>
                                        <kendo-formerror *ngIf="questionForm.controls.question_text.errors?.minlength">
                                            {{itemText.titlecase}} text must be at least 10 characters long.
                                        </kendo-formerror>

                                        <kendo-formerror
                                            *ngIf="!questionForm.controls.question_text.errors?.required && questionForm.controls.question_text.errors?.whitespace">
                                            Whitespace is not allowed.
                                        </kendo-formerror>

                                    </kendo-formfield>
                                </div>
                                <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                                    <kendo-formfield>
                                        <label> Description (for internal use only)</label>
                                        <kendo-editor formControlName="description" class="editor_style">
                                            <kendo-toolbar>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorItalicButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorUnderlineButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorStrikethroughButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorAlignLeftButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignCenterButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignRightButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignJustifyButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorInsertOrderedListButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorIndentButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorOutdentButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorCreateLinkButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorUnlinkButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                                                <kendo-toolbar-button kendoEditorViewSourceButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar>
                                        </kendo-editor>
                                        <kendo-formerror *ngIf="questionForm.controls.description.errors?.minlength">
                                            Description must be at least 10 characters long.
                                        </kendo-formerror>
                                    </kendo-formfield>
                                </div>
                                <div class="col-12 col-xl-6 col-lg-6 col-md-6">
                                    <kendo-formfield>
                                        <label><span>*</span> Is Active?</label>
                                        <kendo-switch formControlName="is_active" #is_active [onLabel]="'Yes'"
                                            [offLabel]="'No'">
                                        </kendo-switch>
                                    </kendo-formfield>
                                </div>
                            </div>
                        </div>
                        <div class="text-center">

                            <div class="all_btn">
                                <button type="button" kendoButton
                                (click)="backToList()"
                                    [primary]="true" class="themeoption_btn"
                                    *ngIf="globals.permissions['question-list']">
                                    Cancel
                                </button>
                            </div>


                            <button *ngIf="id==0" kendoButton (click)="addUpdate()" [primary]="true"
                                class="all_btn theme_btn">
                                Add
                            </button>

                            <div kendoTooltip *ngIf="id != 0" class="all_btn"
                                [title]="questionForm.pristine ? 'Please edit the form to enable the button.' : ''">
                                <button kendoButton (click)="addUpdate()" [primary]="true"
                                    [disabled]="questionForm.pristine" class="theme_btn">
                                    Update
                                </button>
                            </div>


                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</div>