import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
declare var $, Swal: any;


import permissionText from "../assets/data/permissionTranslationText.json";

@Injectable()
export class Globals {


  constructor(private http: HttpClient, private CookieService: CookieService) { }

  baseAPIUrl: string = environment.apiUrl;
  baseUrl: string = environment.baseUrl;
  cndURL: string = environment.cndURL;
  authUrl: string = environment.authUrl;
  authUrlReal: string = environment.authUrlReal;
  covidUrl: string = environment.covidUrl;
  dcmUrl: string = environment.dcmUrl;
  connectorUrl: string = environment.connectorUrl;
  TestMode: boolean = environment.TestMode;
  ProductIdArray = ['0eb3cdaf-3ec4-11eb-99e5-cdf45df6b9bb', '256c4fea-3ec4-11eb-99e5-cdf45df6b9bb', '31c5fda4-3ec4-11eb-99e5-cdf45df6b9bb']; // ['Connector','Covid','DCM'];
  ProductId: string = (this.CookieService.get('current_product_id') && this.ProductIdArray.includes(window.atob(this.CookieService.get('current_product_id')))) ? window.atob(this.CookieService.get('current_product_id')) : '31c5fda4-3ec4-11eb-99e5-cdf45df6b9bb';
  ProductPermission = this.CookieService.get('product_permission') ? JSON.parse(window.atob(this.CookieService.get('product_permission'))) : { 'covid': false, 'sat': false };
  amsnLoginUrl: string = environment.ssoLoginUrl + environment.authUrl + '/authentication';
  amsnLogoutUrl: string = environment.ssoLogoutUrl+environment.authUrl;
  headerpath: string = "{'Content-Type': 'application/json','Accept': 'application/json'}";
  IsLoggedIn: boolean = false;
  isLoading: boolean = false;
  currentLink: string = '';
  currentModule: string = '';
  authData = localStorage.getItem('auth_user')
    ? JSON.parse(window.atob(localStorage.getItem('auth_user')))
    : this.CookieService.get('auth_user')
      ? JSON.parse(window.atob(this.CookieService.get('auth_user')))
      : null;
  todaysdate: string = '';
  institute_details = this.CookieService.get('institute_details') ? JSON.parse(window.atob(this.CookieService.get('institute_details'))) : null;
  institute_id = this.CookieService.get('institute_details') && JSON.parse(window.atob(this.CookieService.get('institute_details')))?.institute ? JSON.parse(window.atob(this.CookieService.get('institute_details')))?.institute?.id : null;
  selectedInstitute = this.CookieService.get('selected_institute') ? JSON.parse(window.atob(this.CookieService.get('selected_institute'))) : null;
  sweetalertmessage: string = "<span class='close_msg'>This message box will auto close in <b></b> seconds!</span>";
  sweetalerttimer: number = 5000;
  permissionText = permissionText;
  permissions = {};
  AMSNUserAccess = ['80f93f87-5f6e-4047-a84d-269ab39fac0c'];
  OESSUserAccess = ['c75979d2-53e2-467e-ac76-46dfa0d116af'];
  usersAccessConfigData = ['80f93f87-5f6e-4047-a84d-269ab39fac0c'];
  maximumSearchCriteriaDisplay = 4;
  CookieDomainUrl: string = environment.CookieDomainUrl;
  breadcrumbs = ['', '', ''];
  selected_institute = this.CookieService.get('selected_institute') ? JSON.parse(window.atob(this.CookieService.get('selected_institute'))) : null;
  Recently_institutes = this.CookieService.get('Recently_institutes') ? JSON.parse(window.atob(this.CookieService.get('Recently_institutes'))) : [];
  dialogOpened = false;
  public full_month_name : any = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  public short_month_name : any =  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // grid configuration
  pagesizes = [
    { text: '10', value: 10 },
    { text: '20', value: 20 },
    { text: '50', value: 50 }
  ];
  pageIndex = 6;

  virtual = {
    itemHeight: 30,
    pageSize: 15
  };

  sweetAlert(icon, title, html) {
    let timerInterval;
    Swal.fire({
      icon: icon,
      title: title,
      showConfirmButton: true,
      timer: this.sweetalerttimer,
      html: html + this.sweetalertmessage,
      timerProgressBar: true,
      didOpen: () => {
      
        const b = Swal.getHtmlContainer().querySelector('b')
        timerInterval = setInterval(() => {
          b.textContent = (Swal.getTimerLeft() / 1000).toFixed()
        }, 100)

      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    });
  }

  errorSweetAlert(title = '') {
   
  }

  saveRecentlyInstitutes(institute_id) {
    let Recently_institutes = this.Recently_institutes;
    let index = $.inArray(institute_id, Recently_institutes);
    if (index !== -1) {
      Recently_institutes.splice(index, 1);
    }
    Recently_institutes.unshift(institute_id);
    this.CookieService.set('Recently_institutes', window.btoa(JSON.stringify(Recently_institutes)), 365, '/', this.CookieDomainUrl);
  }

  debounce(func: (...args: any[]) => void, wait: number) {
    let timeout: any;
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  scrollToTop(){
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  }
}
