<div class="inner_content_block">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <div class="title_block">
                    <h1 class="float-start">
                        <ng-template [ngIf]="id==0" [ngIfElse]="updateTitle"> Add a Domain
                        </ng-template>
                        <ng-template #updateTitle> Update a Domain </ng-template>
                    </h1>
                    <a kendoButton routerLink="/{{this.globals.currentLink.split('/')[1]}}/configuration/domain/list"
                        class="all_btn theme_btn float-end" *ngIf="globals.permissions['domain-list']">List of
                        Domains</a>
                    <div class="clearfix"></div>
                </div>
                <p *ngIf="id != 0" class="padding_15">Please be mindful that the change will be reflected immediately to
                    all past,
                    present and future self-assessments.</p>
                <form class="k-form" [formGroup]="domainForm">
                    <fieldset class="k-form-fieldset">
                        <div class="grey_form_block">
                           
                            <div class="row">
                                <div class="col-12 col-xl-6 col-lg-6 col-md-6">
                                    <kendo-formfield [ngClass]="{'k-form-field-error': duplicate_name}"
                                        class="error_height">
                                        <label><span>*</span> Domain Name</label>
                                        <input formControlName="name" #name maxlength="200" kendoTextBox
                                            (keyup)="duplicate_name = false" />
                                        <kendo-formerror *ngIf="domainForm.controls.name.errors?.required">
                                            Please enter domain name.
                                        </kendo-formerror>
                                        <kendo-formerror *ngIf="domainForm.controls.name.errors?.pattern">
                                            Please enter valid domain name. Allowed special characters are dash(-),
                                            underscore(_), round brackets, at the rate(&#64;), exclamation(!), hash(#),
                                            and(&), dollar($), percentage(%), star(*), dot(.), caret(^), double
                                            quotation("), slash(/) and comma(,).
                                        </kendo-formerror>
                                        <div class="k-form-error" *ngIf="duplicate_name">
                                            Domain name is already taken.
                                        </div>
                                    </kendo-formfield>
                                </div>
                                <div class="col-12 col-xl-6 col-lg-6 col-md-6">
                                    <kendo-formfield>
                                        <label><span>*</span> Is Active?</label>
                                        <kendo-switch formControlName="is_active" #is_active [onLabel]="'Yes'"
                                            [offLabel]="'No'">
                                        </kendo-switch>
                                    </kendo-formfield>
                                </div>
                                <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                                    <kendo-formfield>
                                        <label>Description (for internal use only)</label>
                                        <textarea formControlName="description" #description maxlength="2000"
                                            kendoTextArea></textarea>
                                        <kendo-formerror *ngIf="domainForm.controls.description.errors?.pattern">
                                            Please enter valid description. Allowed special characters are dash(-),
                                            underscore(_), round brackets, at the rate(&#64;), exclamation(!), hash(#),
                                            and(&), dollar($), percentage(%), star(*), dot(.), caret(^), double
                                            quotation("), slash(/) and comma(,).
                                        </kendo-formerror>
                                    </kendo-formfield>
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <button kendoButton (click)="addUpdate()" [primary]="true" class="all_btn theme_btn">
                                <ng-template [ngIf]="id==0" [ngIfElse]="updateButton"> Add </ng-template>
                                <ng-template #updateButton> Update </ng-template>
                            </button>
                            <button type="button" kendoButton
                                routerLink="/{{this.globals.currentLink.split('/')[1]}}/configuration/domain/list"
                                [primary]="true" class="all_btn themeoption_btn"
                                *ngIf="globals.permissions['domain-list']">
                                Cancel
                            </button>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</div>