import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Globals } from '../globals';
import { CookieService } from 'ngx-cookie-service';
declare var $, Swal: any;

@Component({
  selector: 'app-landing-dashboard',
  templateUrl: './landing-dashboard.component.html',
  styleUrls: ['./landing-dashboard.component.css'],
})
export class LandingDashboardComponent implements OnInit {
  instituteList: any = [];
  individual = null;
  role = 0;
  individual_present = false;
  amsn_present = false;
  oess_present = false;
  institute_count = 0;
  selectForm: UntypedFormGroup;
  UserRoleList: any = [];
  product: string = null;
  productList: any = [];
  disableBtn: boolean = true;
  product_value = 0;
  institute_value = null;
  institute_details = null;
  last_role = null;
  last_product = null;
  displayProduct: boolean = false;

  administrator_present: boolean = false;
  admin_product_id;
  admin_product_value;
  admin_institute_details = null;
  connector_present: boolean = false;
  covid_present: boolean = false;
  dcm_present: boolean = false;

  constructor(
    public globals: Globals,
    private AuthService: AuthService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private CookieService: CookieService
  ) {}

  ngOnInit(): void {
    let postdata = { user_id: this.globals.authData?.id };

    this.AuthService.getUserProductRoleList(postdata).then(
      (data) => {
        
        let productList = data['data'];
        let connector_present = false;
        let covid_present = false;
        let dcm_present = false;
        let administrator_present = false;
        let admin_product_id = '31c5fda4-3ec4-11eb-99e5-cdf45df6b9bb';
        let admin_product_value = null;
        let admin_institute_details = null;
        let admin_covid_present = false;
        let admin_dcm_present = false;
        this.productList = productList.filter((value) => {
          if (value.role_list.length > 0) {
            if (value.product_value == 1) {
              connector_present = true;
            } else if (value.product_value == 3) {
              value.role_list.forEach(function (item) {
                if (
                  item.role_value == 1 ||
                  item.role_value == 2 ||
                  item.role_value == 3 ||
                  item.role_value == 6
                ) {
                  dcm_present = true;
                 
                  admin_product_value =
                    admin_product_value == null ? 3 : admin_product_value;
                  administrator_present = true;
                  admin_dcm_present = true;
                  admin_institute_details =
                    admin_institute_details == null
                      ? item
                      : admin_institute_details;
                }
              });
            } else if (value.product_value == 2) {
              value.role_list.forEach(function (item) {
                if (item.role_value == 1 || item.role_value == 2) {
                  covid_present = true;
                 
                  admin_product_value =
                    admin_product_value == null ? 2 : admin_product_value;
                  administrator_present = true;
                  admin_covid_present = true;
                  admin_institute_details =
                    admin_institute_details == null
                      ? item
                      : admin_institute_details;
                }
              });
            }
            return value;
          }
        });
        this.globals.ProductPermission.covid = admin_covid_present;
        this.globals.ProductPermission.dcm = admin_dcm_present;
        this.CookieService.set(
          'product_permission',
          window.btoa(JSON.stringify(this.globals.ProductPermission)),
          365,
          '/',
          this.globals.CookieDomainUrl
        );
        this.connector_present = connector_present;
        this.covid_present = covid_present;
        this.dcm_present = dcm_present;
        this.administrator_present = administrator_present;
        this.admin_product_id = admin_product_id;
        this.admin_product_value = admin_product_value;
        this.admin_institute_details = admin_institute_details;

        if (this.admin_product_id != this.globals.ProductId) {
          this.CookieService.set(
            'current_product_id',
            window.btoa(this.admin_product_id),
            365,
            '/',
            this.globals.CookieDomainUrl
          );
          this.globals.ProductId = this.admin_product_id;
          this.globals.institute_details = null;
          this.CookieService.delete(
            'institute_details',
            '/',
            this.globals.CookieDomainUrl
          );
          this.globals.authData = '';
          this.CookieService.delete('auth_user', '/', this.globals.CookieDomainUrl);

          window.location.reload();
        }
        if (administrator_present) {
          let product_value;
          let institute_details;
          product_value = this.admin_product_value;
          institute_details = this.admin_institute_details;

          this.CookieService.set(
            'institute_details',
            window.btoa(JSON.stringify(institute_details)),
            365,
            '/',
            this.globals.CookieDomainUrl
          );
          this.globals.institute_details = institute_details;
          this.CookieService.set(
            'current_product_id',
            window.btoa(this.admin_product_id),
            365,
            '/',
            this.globals.CookieDomainUrl
          );
          this.globals.ProductId = this.admin_product_id;
          
          window.location.href = this.globals.baseUrl + '/';
        } else {
          window.location.href = this.globals.authUrlReal;
        }
      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
    ;
    this.globals.isLoading = true;

    this.product = this.route.snapshot.paramMap.get('product')
      ? this.route.snapshot.paramMap.get('product')
      : null;
    this.selectForm = this.fb.group({
      role: ['', [Validators.required]],
    });

    
  }

  advanced_search_block() {
    $('.advanced_search_block').toggleClass('active');
    $('.search_main_block').toggleClass('right_slide');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  selectOrg(role, institute_id, index) {
    ;
    this.disableBtn = false;
    this.institute_value = index;
    $('.institute_block').removeClass('checked');
    $('#institute' + index)
      .parent()
      .addClass('checked');
    this.institute_details = this.instituteList[index];
  }

  selectProduct(index, value) {
    ;
    this.instituteList = [];
    this.product_value = value;
    this.disableBtn = true;
    $('.product_block').removeClass('checked');
    $('#product' + index)
      .parent()
      .addClass('checked');

    let institutes: any = [];
    let institute_details: any = null;
    this.productList.forEach(function (item) {
      if (item.product_value == value && item.role_list.length > 0) {
        institutes = item.role_list;
      }
    });
    let instituteList = institutes.filter((item) => {
      if (item.institute != '' && item.institute != null) {
        return item;
      } else {
        institute_details =
          institute_details == null ? item : institute_details;
      }
    });
    if (instituteList.length == institutes.length) {
      this.instituteList = instituteList;
      if (this.instituteList.length > 1) {
        this.disableBtn = true;
      } else {
        this.disableBtn = false;
      }
      this.institute_details = null;
    } else {
      this.institute_details = institute_details;
      this.disableBtn = false;
    }

  }

  viewProduct() {
    
  }
}
