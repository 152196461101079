<div class="inner_content_block">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <div class="clearfix"></div>
                <div class="title_block">
                    <h1 class="float-start">
                        IFMA’s self-assessment Items per Subdomain
                    </h1>
                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>

                <h5>Total Items per self-sssessment is set to be {{NoOfTotalAssessmentQuestions}}</h5>
                <h5>Minimum Items per self-assessment is set to be {{NoOfMinimumAssessmentQuestions}}</h5>
                <div class="clearfix"></div>
                <kendo-grid [data]="domainList" class="subdomain_table" scrollable="none">

                    <kendo-grid-column field="domain_name" title="Domain" [headerStyle]="{width: '20%'}"  [style]="{width: '20%'}" media="(min-width: 769px)">
                        <ng-template kendoGridFooterTemplate>Total</ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="name" title="Subdomain" [headerStyle]="{width: '20%'}"  [style]="{width: '20%'}" media="(min-width: 769px)">
                    </kendo-grid-column>

                    <kendo-grid-column field="total_question" title="Total Items per Subdomain" [headerClass]="'justify-content-center'" [headerStyle]="{width: '15%','text-align': 'center'}" [style]="{width: '15%'}" media="(min-width: 769px)" [footerStyle]="{width: '15%','text-align': 'center'}"
                        class="text-center">
                        <ng-template kendoGridFooterTemplate>{{total_questions}}</ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="total_active_question" title="Total Active Items per Subdomain" [headerClass]="'justify-content-center'"
                    [headerStyle]="{width: '15%','text-align': 'center'}" [style]="{width: '15%'}" media="(min-width: 769px)" [footerStyle]="{width: '15%','text-align': 'center'}"
                        class="text-center">
                        <ng-template kendoGridFooterTemplate>{{total_active_questions}}</ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="number_of_questions" title="Total Items for self-assessment" [headerClass]="'justify-content-center'" [headerStyle]="{width: '15%','text-align': 'center'}" [style]="{width: '15%'}" media="(min-width: 769px)" [footerStyle]="{width: '15%','text-align': 'center'}"
                        class="text-center">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div kendoTooltip>
                                <input type="number" [(ngModel)]="dataItem.number_of_questions"
                                    name="number_of_questions{{rowIndex}}" class="text-center"
                                    [ngClass]="{'error': dataItem.number_of_questions > dataItem.total_active_question || dataItem.number_of_questions < 0}"
                                    (keyup)="checkSum(dataItem.number_of_questions, rowIndex)" maxlength="4"
                                    [attr.title]="
                                    (dataItem.number_of_questions > dataItem.total_active_question) 
                                    ? 'No. of Items should not be more than ' + dataItem.total_active_question + '.' 
                                    : ((dataItem.number_of_questions < 0) ? 'Invalid Value' : '')" kendoTextBox />
                            </div>
                        </ng-template>
                        <ng-template kendoGridFooterTemplate>
                            <div kendoTooltip>
                                <span *ngIf="total_questions_for_assessment != NoOfTotalAssessmentQuestions" class="red"
                                    title='No. of Total Items for self-assessment should be {{NoOfTotalAssessmentQuestions}}.'>{{total_questions_for_assessment}}</span>
                                <span
                                    *ngIf="total_questions_for_assessment == NoOfTotalAssessmentQuestions">{{total_questions_for_assessment}}</span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="attempt_questions" title="Mandatory Items for self-assessment" [headerClass]="'justify-content-center'" [headerStyle]="{width: '15%','text-align': 'center'}" [style]="{width: '15%'}" media="(min-width: 769px)" [footerStyle]="{width: '15%','text-align': 'center'}"
                        class="text-center">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div kendoTooltip>
                                <input type="number" [(ngModel)]="dataItem.attempt_questions"
                                    name="attempt_questions{{rowIndex}}" class="text-center"
                                    [ngClass]="{'error': dataItem.attempt_questions > dataItem.number_of_questions || dataItem.number_of_questions < 0}"
                                    (keyup)="checkSumMandatory(dataItem.attempt_questions, rowIndex)" maxlength="4"
                                    [attr.title]="
                                    (dataItem.attempt_questions > dataItem.number_of_questions) 
                                    ? 'No. of Mandatory Items should not be more than ' + dataItem.number_of_questions + '.' 
                                    : ((dataItem.attempt_questions < 0) ? 'Invalid Value' : '')" kendoTextBox />
                            </div>
                        </ng-template>
                        <ng-template kendoGridFooterTemplate>
                            <div kendoTooltip>
                                <span *ngIf="mandatory_questions_for_assessment < NoOfMinimumAssessmentQuestions"
                                    class="red"
                                    title='No. of Mandatory Items for self-assessment should not be more than {{NoOfMinimumAssessmentQuestions}}.'>{{mandatory_questions_for_assessment}}</span>
                                <span *ngIf="mandatory_questions_for_assessment > total_questions_for_assessment"
                                    class="red"
                                    title='No. of Mandatory Items for self-assessment should not be more than {{total_questions_for_assessment}}.'>{{mandatory_questions_for_assessment}}</span>
                                <span
                                    *ngIf="mandatory_questions_for_assessment >= NoOfMinimumAssessmentQuestions && mandatory_questions_for_assessment <= total_questions_for_assessment">{{mandatory_questions_for_assessment}}</span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>


                    <kendo-grid-column field="domain_name" title="Domain" width="200" media="(max-width: 768px)">
                        <ng-template kendoGridFooterTemplate>Total</ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="name" title="Subdomain" width="200" media="(max-width: 768px)">
                    </kendo-grid-column>

                    <kendo-grid-column field="total_question" title="Total Items per Subdomain" width="100" [headerClass]="'justify-content-center'"
                        [headerStyle]="{'text-align': 'center'}" [footerStyle]="{'text-align': 'center'}" media="(max-width: 768px)"
                        class="text-center">
                        <ng-template kendoGridFooterTemplate>{{total_questions}}</ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="total_active_question" title="Total Active Items per Subdomain" [headerClass]="'justify-content-center'"
                        width="100" [headerStyle]="{'text-align': 'center'}" [footerStyle]="{'text-align': 'center'}" media="(max-width: 768px)"
                        class="text-center">
                        <ng-template kendoGridFooterTemplate>{{total_active_questions}}</ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="number_of_questions" title="Total Items for self-assessment" width="100" [headerClass]="'justify-content-center'"
                        [headerStyle]="{'text-align': 'center'}" [footerStyle]="{'text-align': 'center'}" media="(max-width: 768px)"
                        class="text-center">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div kendoTooltip>
                                <input type="number" [(ngModel)]="dataItem.number_of_questions"
                                    name="number_of_questions{{rowIndex}}" class="text-center"
                                    [ngClass]="{'error': dataItem.number_of_questions > dataItem.total_active_question || dataItem.number_of_questions < 0}"
                                    (keyup)="checkSum(dataItem.number_of_questions, rowIndex)" maxlength="4"
                                    [attr.title]="
                                    (dataItem.number_of_questions > dataItem.total_active_question) 
                                    ? 'No. of Items should not be more than ' + dataItem.total_active_question + '.' 
                                    : ((dataItem.number_of_questions < 0) ? 'Invalid Value' : '')" kendoTextBox />
                            </div>
                        </ng-template>
                        <ng-template kendoGridFooterTemplate>
                            <div kendoTooltip>
                                <span *ngIf="total_questions_for_assessment != NoOfTotalAssessmentQuestions" class="red"
                                    title='No. of Total Items for self-assessment should be {{NoOfTotalAssessmentQuestions}}.'>{{total_questions_for_assessment}}</span>
                                <span
                                    *ngIf="total_questions_for_assessment == NoOfTotalAssessmentQuestions">{{total_questions_for_assessment}}</span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="attempt_questions" title="Mandatory Items for self-assessment" width="100" [headerClass]="'justify-content-center'"
                        [headerStyle]="{'text-align': 'center'}" [footerStyle]="{'text-align': 'center'}" media="(max-width: 768px)"
                        class="text-center">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div kendoTooltip>
                                <input type="number" [(ngModel)]="dataItem.attempt_questions"
                                    name="attempt_questions{{rowIndex}}" class="text-center"
                                    [ngClass]="{'error': dataItem.attempt_questions > dataItem.number_of_questions || dataItem.number_of_questions < 0}"
                                    (keyup)="checkSumMandatory(dataItem.attempt_questions, rowIndex)" maxlength="4"
                                    [attr.title]="
                                    (dataItem.attempt_questions > dataItem.number_of_questions) 
                                    ? 'No. of Mandatory Items should not be more than ' + dataItem.number_of_questions + '.' 
                                    : ((dataItem.attempt_questions < 0) ? 'Invalid Value' : '')" kendoTextBox />
                            </div>
                        </ng-template>
                        <ng-template kendoGridFooterTemplate>
                            <div kendoTooltip>
                                <span *ngIf="mandatory_questions_for_assessment < NoOfMinimumAssessmentQuestions"
                                    class="red"
                                    title='No. of Mandatory Items for self-assessment should not be more than {{NoOfMinimumAssessmentQuestions}}.'>{{mandatory_questions_for_assessment}}</span>
                                <span *ngIf="mandatory_questions_for_assessment > total_questions_for_assessment"
                                    class="red"
                                    title='No. of Mandatory Items for self-assessment should not be more than {{total_questions_for_assessment}}.'>{{mandatory_questions_for_assessment}}</span>
                                <span
                                    *ngIf="mandatory_questions_for_assessment >= NoOfMinimumAssessmentQuestions && mandatory_questions_for_assessment <= total_questions_for_assessment">{{mandatory_questions_for_assessment}}</span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
                <div class="clearfix"></div>
                <div class="text-center mt-5">
                    <button kendoButton (click)="update()" [primary]="true" class="all_btn theme_btn"
                        *ngIf="globals.permissions['edit-configuration']">
                        Update
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>