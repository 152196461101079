import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { AuthService } from '../services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
declare var $, PerfectScrollbar: any;


@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html'
})
export class SideMenuComponent implements OnInit {

  permissions: any = [];
  accessConfigData: boolean = false;
  administrator_present:boolean = false;
  connector_present:boolean = false;
  covid_present:boolean = false;
  dcm_present:boolean = false;
  productList:any = [];

  constructor(public globals: Globals, private router: Router, private authService: AuthService, private CookieService: CookieService) { }


  ngOnInit(): void {
    console.log(this.globals.currentLink.split('/'))
    this.getPermission();
      if(this.globals.authData.id!=this.CookieService.get('user_token')){
        setTimeout(() => {
          this.authService.getAuthUser()
          .then((data) => { 
            this.getMyProductData();
          },
          (error) => {
            this.globals.isLoading = false;
            this.globals.errorSweetAlert();
          });
        }, 500);
      } else {
        this.getMyProductData();
      }  
    setTimeout(function () {
      new PerfectScrollbar('.menu_block');
    }, 500);
    if(screen.width < 1200){
      $(".sidebar_block").addClass("small_menu");
      $(".sidebar_block .expand_collapse_block button i").addClass("fa-angle-left");
      $(".sidebar_block .expand_collapse_block button i").removeClass("fa-angle-right");
      $(".right_wrapper").addClass("full_right_block");
    }
  }

  getMyProductData(){
    let postdata = {'user_id':this.globals.authData.id};
    this.authService.getUserRoleList(postdata)
    .then((data) => {
      let productList = data['data'];
      let connector_present = false; let covid_present = false; let dcm_present = false; 
      let administrator_present = false;

      this.productList = productList.filter((value) => {
        if(value.role_value==1 || value.role_value==2){           
          value.product_list.forEach(function (item) {
            if(item.product.value==1){
              connector_present = true;
            } else if(item.product.value==2){
              administrator_present = true;
              covid_present = true;
            } else if(item.product.value==3){
              administrator_present = true;
              dcm_present = true;
            } 
          });
        } else if(value.role_value==5){
          value.product_list.forEach(function (item) {
            if(item.product.value==2){
              covid_present = true;
            } else if(item.product.value==3){
              dcm_present = true;
            }
          });
        } else if(value.role_value==4){
          value.product_list.forEach(function (item) {
            if(item.product.value==3){
              dcm_present = true;
            }
          });
        } else if(value.role_value==3 || value.role_value==6){
          let admin_present = false;
          value.product_list.forEach(function (item) {
            if(item.product.value==1){
              connector_present = true;
            } else if(item.product.value==3){
              administrator_present = true;
              dcm_present = true;
            }
          });         
        }
        return value;
      });
      this.connector_present = connector_present;
      this.covid_present = covid_present;
      this.dcm_present = dcm_present;
      this.administrator_present = administrator_present;
      this.globals.isLoading = false;
    }, (error) => {
      this.globals.isLoading = false;
      this.globals.errorSweetAlert();
    });
  }

  expand_collapse() {
    $(".sidebar_block").toggleClass("small_menu");
    $(".sidebar_block .expand_collapse_block button i").toggleClass("fa-angle-left");
    $(".sidebar_block .expand_collapse_block button i").toggleClass("fa-angle-right");
    $(".right_wrapper").toggleClass("full_right_block");
    this.resultSlider();
  }
  mobile_expand(){
    $(".sidebar_block").addClass("active");
    $(".sidebar_block").removeClass("small_menu");
    this.resultSlider();
  }
  close_mobile_menu(){
    $(".sidebar_block").removeClass("active");
    $(".sidebar_block").addClass("small_menu");
    this.resultSlider();
  }

  getPermission() {
    
    if (localStorage.getItem('last_updated_date_role_permission') && localStorage.getItem('user_role_permission')) {
      this.authService.getLastUpdatedDateOfRolePermission()
        .then((res: any) => {
          let lastUpdatedDateRolePermission = new Date(res['data'].updated_at);
          let cookie_date = new Date(window.atob(localStorage.getItem('last_updated_date_role_permission')));
          if (lastUpdatedDateRolePermission <= cookie_date) {
            if (window.atob(this.CookieService.get('current_role_value')) == this.globals.institute_details?.role_value) {
              let data = JSON.parse(window.atob(localStorage.getItem('user_role_permission')));
              this.globals.permissions = data;
            } else {
              this.CookieService.set('current_role_value', window.btoa(this.globals.institute_details?.role_value), 365, '/', this.globals.CookieDomainUrl);
              //localStorage.setItem('current_role_value', window.btoa(this.globals.institute_details?.role_value));
              let permission_data = JSON.parse(window.atob(localStorage.getItem('all_role_permission')));
              let active_license = (this.globals.institute_details && (this.globals.institute_details.active_license > 0)) ? 0 : 0;
              if (this.globals.institute_details) {
                let retrievedObject1 = (permission_data.filter((item) => {
                  if (item.role_value == this.globals.institute_details?.role_value && item.is_accessible_after_expire == active_license) {
                    return item;
                  }
                }))[0].permission_list;

                let user_role_permission = {};
                let index;
                this.globals.permissionText.forEach(function (menu, key) {
                  index = retrievedObject1.findIndex(retrievedObject1 => (retrievedObject1.slug === menu.key && retrievedObject1.has_access == 1));
                  if (index != -1) {
                    user_role_permission[menu.key] = true;
                  } else {
                    user_role_permission[menu.key] = false;
                  }
                });
                localStorage.setItem('user_role_permission', window.btoa(JSON.stringify(user_role_permission)));
                this.globals.permissions = user_role_permission;
              }
            }
          } else {
            this.authService.getAllRolePermissionData1();
          }
        },
          (error) => {
            this.globals.isLoading = false;
            this.globals.errorSweetAlert();
          });
    } else {
      this.authService.getAllRolePermissionData1();
    }
  }

  changeProduct(ProductId, url){ 
    this.globals.isLoading = true;
    this.CookieService.set('current_product_id', window.btoa(ProductId), 365, '/', this.globals.CookieDomainUrl);
    this.globals.ProductId = ProductId;
    localStorage.removeItem('user_role_permission');

    this.authService.getAuthUser()
    .then((data) => {

      let instituteList = this.globals.authData.list.filter((s) => (s.role_value == 1 || s.role_value == 2 || s.role_value == 3 || s.role_value == 6));

      let instituteList_new = [];
      let instituteList_admin = [];
      if(this.globals.selected_institute != null){
        instituteList_new = instituteList.filter((s) => ((s.role_value == 3 || s.role_value == 6) && s.institute.id == this.globals.selected_institute.id));
        instituteList_admin = instituteList.filter((s) => ((s.role_value == 1 || s.role_value == 2)));
      }      
      let institute_details = instituteList_admin.length > 0 ? instituteList_admin[0] : (instituteList_new.length > 0 ? instituteList_new[0] : instituteList[0]);

      this.CookieService.set('institute_details', window.btoa(JSON.stringify(institute_details)), 365, '/', this.globals.CookieDomainUrl);
      
      this.globals.institute_details = institute_details;

      window.location.href = this.globals.baseUrl + '/' + url;
      //this.router.navigate(['/'+url]);

    }, (error) => {
      this.globals.isLoading = false;
      this.globals.errorSweetAlert();
    });      

  }

  resultSlider(){
    $('.owl.carousel').trigger('destroy.owl.carousel');
    $('#main-block').data('owlCarousel').destroy();
    $('#thumbs-block').data('owlCarousel').destroy();
    $('#main-block1').data('owlCarousel').destroy();
    $('#thumbs-block1').data('owlCarousel').destroy();
    setTimeout(() => {
      var sync1 = $("#main-block");
      var sync2 = $("#thumbs-block");
      sync1.owlCarousel({
        singleItem: true,
        autoPlay: false,
        slideSpeed: 1000,
        navigation: false,
        // navigationText: ["Prev Subdomain", "Next Subdomain"],
        loop: false,
        rewindNav: false,
        mouseDrag: false,
        touchDrag: false,
        pagination: false,
        autoHeight: true,
        afterAction: syncPosition,
        responsiveRefreshRate: 200,
      });
      sync2.owlCarousel({
        items: 4,
        itemsDesktopBig: [1500, 4],
        itemsDesktop: [1199, 3],
        itemsDesktopSmall: [979, 2],
        itemsTablet: [768, 1],
        itemsMobile: [479, 1],
        pagination: false,
        loop: false,
        rewindNav: false,
        navigation: true,
        mouseDrag: false,
        touchDrag: false,
        navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        responsiveRefreshRate: 100,
        afterInit: function (el) {
          el.find(".owl-item").eq(0).addClass("synced");
        }
      });
      function syncPosition(el) {
        var current = this.currentItem;
        $("#thumbs-block")
          .find(".owl-item")
          .removeClass("synced")
          .eq(current)
          .addClass("synced")
        if ($("#thumbs-block").data("owlCarousel") !== undefined) {
          center(current)
        }
      }
      $("#thumbs-block").on("click", ".owl-item", function (e) {
        e.preventDefault();
        var number = $(this).data("owlItem");
        sync1.trigger("owl.goTo", number);
      });
      function center(number) {
        var sync2visible = sync2.data("owlCarousel").owl.visibleItems;
        var num = number;
        var found = false;
        for (var i in sync2visible) {
          if (num === sync2visible[i]) {
            var found = true;
          }
        }
        if (found === false) {
          if (num > sync2visible[sync2visible.length - 1]) {
            sync2.trigger("owl.goTo", num - sync2visible.length + 2)
          } else {
            if (num - 1 === -1) {
              num = 0;
            }
            sync2.trigger("owl.goTo", num);
          }
        } else if (num === sync2visible[sync2visible.length - 1]) {
          sync2.trigger("owl.goTo", sync2visible[1])
        } else if (num === sync2visible[0]) {
          sync2.trigger("owl.goTo", num - 1)
        }
      }

      var sync3 = $("#main-block1");
      var sync4 = $("#thumbs-block1");
      sync3.owlCarousel({
        singleItem: true,
        autoPlay: false,
        slideSpeed: 1000,
        navigation: false,
        //  navigationText: ["Prev Subdomain", "Next Subdomain"],
        loop: false,
        rewindNav: false,
        mouseDrag: false,
        touchDrag: false,
        pagination: false,
        autoHeight: true,
        afterAction: syncPosition1,
        responsiveRefreshRate: 200,
      });
      sync4.owlCarousel({
        items: 4,
        itemsDesktopBig: [1500, 4],
        itemsDesktop: [1199, 3],
        itemsDesktopSmall: [979, 2],
        itemsTablet: [768, 1],
        itemsMobile: [479, 1],
        pagination: false,
        loop: false,
        rewindNav: false,
        navigation: true,
        mouseDrag: false,
        touchDrag: false,
        navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        responsiveRefreshRate: 100,
        afterInit: function (el) {
          el.find(".owl-item").eq(0).addClass("synced");
        }
      });
      function syncPosition1(el) {
        var current = this.currentItem;
        $("#thumbs-block1")
          .find(".owl-item")
          .removeClass("synced")
          .eq(current)
          .addClass("synced")
        if ($("#thumbs-block1").data("owlCarousel") !== undefined) {
          center1(current)
        }
      }
      $("#thumbs-block1").on("click", ".owl-item", function (e) {
        e.preventDefault();
        var number = $(this).data("owlItem");
        sync3.trigger("owl.goTo", number);
      });
      function center1(number) {
        var sync4visible = sync4.data("owlCarousel").owl.visibleItems;
        var num = number;
        var found = false;
        for (var i in sync4visible) {
          if (num === sync4visible[i]) {
            var found = true;
          }
        }
        if (found === false) {
          if (num > sync4visible[sync4visible.length - 1]) {
            sync4.trigger("owl.goTo", num - sync4visible.length + 2)
          } else {
            if (num - 1 === -1) {
              num = 0;
            }
            sync4.trigger("owl.goTo", num);
          }
        } else if (num === sync4visible[sync4visible.length - 1]) {
          sync4.trigger("owl.goTo", sync4visible[1])
        } else if (num === sync4visible[0]) {
          sync4.trigger("owl.goTo", num - 1)
        }
      }
    }, 500);
  }

}
