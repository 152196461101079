import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Globals } from './globals';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorClassService } from './http-interceptor-class.service';

// Components
import { IntermediatePageComponent } from './intermediate-page/intermediate-page.component';
import { LandingDashboardComponent } from './landing-dashboard/landing-dashboard.component';
import { LoginComponent } from './login/login.component';
import { PermissionNotFoundComponent } from './permission-not-found/permission-not-found.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthenticationFailedComponent } from './authentication-failed/authentication-failed.component';

// Services
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { DomainService } from './services/domain.service';
import { SubdomainService } from './services/subdomain.service';
import { QuestionService } from './services/question.service';

const routes: Routes = [
  { path: '', redirectTo: 'sat', pathMatch: 'full' },
  {
    path: 'authentication',
    component: IntermediatePageComponent,
    canActivate: [AuthGuard],
    data: { title: 'Authentication' } 
  },
  {
    path: 'landing-dashboard',
    component: LandingDashboardComponent,
    canActivate: [AuthGuard],
    data: { title: 'Landing Dashboard' } 
  },
  {
    path: 'permission-not-found',
    component: PermissionNotFoundComponent,
    canActivate: [AuthGuard],
    data: { title: 'Permission Not Found' } 
  },
  {
    path: 'page-not-found',
    component: NotFoundComponent,
    canActivate: [AuthGuard],
    data: { title: 'Page Not Found' }  
  },
  { 
    path: 'authentication-failed', 
    component: AuthenticationFailedComponent, 
    data: { title: 'Authentication Failed' } 
  },
  {
    path: 'sat',
    data: { product: 'DCM' },
    loadChildren: () =>
      import('./app-dcm/app-dcm.module').then((m) => m.AppDcmModule),
  },
  {
    path: 'covid',
    data: { product: 'COVID' },
    loadChildren: () =>
      import('./app-covid/app-covid.module').then((m) => m.AppCovidModule),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./app-general/app-general.module').then(
        (m) => m.AppGeneralModule
      ),
  },
  { path: '**', redirectTo: 'sat' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [
    Globals,
    AuthService,
    AuthGuard,
    DomainService,
    SubdomainService,
    QuestionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorClassService,
      multi: true,
    },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
