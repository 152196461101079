import { Component, OnInit } from '@angular/core';
import { Globals } from '../../globals';
import { Router, ActivatedRoute } from '@angular/router';
import { DomainService } from '../../services/domain.service';
import { SubdomainService } from '../../services/subdomain.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
declare var Swal: any;


@Component({
    selector: 'app-sub-domain',
    templateUrl: './sub-domain.component.html'
})
export class SubDomainComponent implements OnInit {

    id;
    subdomainForm: UntypedFormGroup;
    duplicate_name: boolean = false;
    domainList = [];
    domainListFilterData: any;

    constructor(public globals: Globals, private router: Router, private route: ActivatedRoute,
        private SubdomainService: SubdomainService, private formBuilder: UntypedFormBuilder, private DomainService: DomainService) { }

    ngOnInit(): void {
        let self = this;
        setTimeout(function () {
            self.globals.breadcrumbs = [self.route.snapshot.data['product'], 'Manage', 'Subdomains'];
        }, 100);

        if(this.globals.currentModule == 'sat'){
            this.subdomainForm = this.formBuilder.group({
                subdom_provider_id: [''],
                domain_id: ['', [Validators.required]],
                name: ['', [Validators.required, Validators.pattern("^[A-Za-z0-9!@#$%^&*()-_.,/']+[A-Za-z0-9!@#$%^&*()-_.,/' ]+$")]],
                description: ['', [Validators.pattern("^[A-Za-z0-9!@#$%^&*()-_.,/']+[A-Za-z0-9!@#$%^&*()-_.,/' ]+$")]],
                emerging_desc: [''],
                expert_desc: [''],
                established_desc: [''],
                is_active: [1]
            });
        } else {
            this.subdomainForm = this.formBuilder.group({
                subdom_provider_id: [''],
                domain_id: ['', [Validators.required]],
                name: ['', [Validators.required, Validators.pattern("^[A-Za-z0-9!@#$%^&*()-_.,/']+[A-Za-z0-9!@#$%^&*()-_.,/' ]+$")]],
                description: ['', [Validators.pattern("^[A-Za-z0-9!@#$%^&*()-_.,/']+[A-Za-z0-9!@#$%^&*()-_.,/' ]+$")]],
                is_active: [1]
            });
        }
        
        this.globals.isLoading = false;

        this.id = (this.route.snapshot.paramMap.get('id')) ? this.route.snapshot.paramMap.get('id') : 0;

        this.getDomains();


        if (this.id != 0) {
            this.SubdomainService.getById(this.id)
                .then((data) => {
                    var fields = data['data'];
                    if(this.globals.currentModule == 'sat'){
                        this.subdomainForm.patchValue({
                            subdom_provider_id: fields['subdom_provider_id'],
                            domain_id: fields['domain_id'],
                            name: fields['name'],
                            description: fields['description'],
                            emerging_desc: fields['emerging_desc'],
                            expert_desc: fields['expert_desc'],
                            established_desc: fields['established_desc'],
                            is_active: fields['is_active']
                        });
                    } else {
                        this.subdomainForm.patchValue({
                            subdom_provider_id: fields['subdom_provider_id'],
                            domain_id: fields['domain_id'],
                            name: fields['name'],
                            description: fields['description'],
                            is_active: fields['is_active']
                        });
                    }
                    this.globals.isLoading = false;
                },
                    (error) => {
                        this.globals.isLoading = false;
                        this.globals.errorSweetAlert();
                    });
        }
    }

    getDomains() {
        this.DomainService.getActive((this.id != 0) ? 2 : 1)
            .then((data) => {
                var domainList: any;
                domainList = data['data'].filter((s) => s.is_active == 1 || s.id == this.subdomainForm.value.domain_id);
                var defaultState = {
                    id: '',
                    name: 'Select a Domain'
                }
                this.domainList.push(defaultState);
                this.domainList = [...this.domainList, ...domainList];
                this.domainListFilterData = this.domainList;
            },
                (error) => {
                    this.globals.isLoading = false;
                    this.globals.errorSweetAlert();
                });
    }


    addUpdate() {
        this.subdomainForm.markAllAsTouched();
        if (this.subdomainForm.valid) {
            this.subdomainForm.value.is_active =
                (this.subdomainForm.value.is_active == true || this.subdomainForm.value.is_active == 1)
                    ? 1
                    : 0;
            this.globals.isLoading = true;
            if (this.id == 0) {
                this.add(this.subdomainForm.value);
            } else {
                this.update(this.subdomainForm.value, this.id);
            }
        }
    }

    add(entity) {
        this.SubdomainService.add(entity)
            .then((data) => {
                this.globals.isLoading = false;
                this.globals.sweetAlert('success', 'Subdomain Added', 'Subdomain has been added successfully.');
                this.router.navigate(['/' + this.globals.currentLink.split('/')[1] + '/configuration/subdomain/list']);
            },
                (error) => {
                    this.globals.isLoading = false;
                    if (error.error.code == 422) {
                        this.duplicate_name = (error.error.message.name != undefined) ? true : false;
                    } else {
                        this.globals.errorSweetAlert();
                    }
                });
    }

    update(entity, id) {
        this.SubdomainService.update(entity, id)
            .then((data) => {
                this.globals.isLoading = false;
                this.globals.sweetAlert('success', 'Subdomain Updated', 'Subdomain has been updated successfully.');
                this.router.navigate(['/' + this.globals.currentLink.split('/')[1] + '/configuration/subdomain/list']);
            },
                (error) => {
                    this.globals.isLoading = false;
                    if (error.error.code == 422) {
                        this.duplicate_name = (error.error.message.name != undefined) ? true : false;
                    } else {
                        this.globals.errorSweetAlert();
                    }
                });
    }

    domainFilter(value) {
        this.domainList = this.domainListFilterData.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }


}