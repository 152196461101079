<header>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
        <div class="logo_block">
          <a routerLink="/" class="logo_a"><img src="{{globals.cndURL}}assets/images/logo-email.png" alt=""
              class="img-fluid" /></a>
          <span>	<span class="align-top d-inline me-3">Administration</span>
    
          <div class="clearfix displayonlysmall"></div>
          <span class="qa_testing_block" *ngIf="globals.TestMode">Beta Version</span>
        </span>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 textcenter_mobile">
        <div class="user_dropdown without_org_block">
         
          <div class="header_dropdown dropdown">
            <button class="dropdown-toggle user_button">
              <i class="fa fa-user icon_user"></i>
            
              <span class="name"
                [ngClass]="{'name_padding':!(globals.institute_details && globals.institute_details.role_name!='')}"><strong class="pe-none">{{globals.authData.first_name}}
                {{globals.authData.last_name}} </strong>
                <span class="id_box mb-0 ms-2">{{globals.authData.amsn_user_id}}</span>
                <i *ngIf="globals.institute_details" class="role_margin"><b>Current Role:</b>{{globals.institute_details.role_name}}</i>
                </span>
            </button>
          </div>
          <div class="logout_switch_org float-end only_login">
          <button kendoButton class="logout_btn" (click)="logout()">
            <i class="fa fa-sign-out"></i>Logout
          </button>
        </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</header>