import { Injectable } from '@angular/core';
import { Globals } from '.././globals';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { dateFieldName } from '@progress/kendo-angular-intl';

@Injectable()
export class AuthService {
  constructor(
    private http: HttpClient,
    private globals: Globals,
    private CookieService: CookieService,
    private router: Router
  ) {}

  checkLogin(loginEntity) {
    let jwtHelper = new JwtHelperService();
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/login', loginEntity)
        .toPromise()
        .then(
          (res) => {
            this.globals.isLoading = true;
            let result = res['data'];
            if (result && result['access_token']) {
              this.globals.authData = '';
             
              this.CookieService.delete(
                'selected_institute',
                '/',
                this.globals.CookieDomainUrl
              );
              this.CookieService.delete(
                'expires_at',
                '/',
                this.globals.CookieDomainUrl
              );
              this.CookieService.delete(
                'access_token',
                '/',
                this.globals.CookieDomainUrl
              );
              this.CookieService.set(
                'access_token',
                result['access_token'],
                1,
                '/',
                this.globals.CookieDomainUrl
              );
              this.CookieService.set(
                'expires_at',
                window.btoa(result['expires_at']),
                1,
                '/',
                this.globals.CookieDomainUrl
              );
              this.CookieService.set(
                'token_type',
                window.btoa(result['token_type']),
                365,
                '/',
                this.globals.CookieDomainUrl
              );
            }
            resolve(res);
          },
          (msg) => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  checkAMSNLogin(token) {
    let jwtHelper = new JwtHelperService();
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/client-login', token)
        .toPromise()
        .then(
          (res) => {
            this.globals.isLoading = true;
            let result = res['data'];
            if (result && result['access_token']) {
              this.globals.authData = '';
            
              this.CookieService.delete(
                'expires_at',
                '/',
                this.globals.CookieDomainUrl
              );
              this.CookieService.delete(
                'access_token',
                '/',
                this.globals.CookieDomainUrl
              );
              this.CookieService.set(
                'access_token',
                result['access_token'],
                1,
                '/',
                this.globals.CookieDomainUrl
              );
              this.CookieService.set(
                'expires_at',
                window.btoa(result['expires_at']),
                1,
                '/',
                this.globals.CookieDomainUrl
              );
              this.CookieService.set(
                'token_type',
                window.btoa(result['token_type']),
                365,
                '/',
                this.globals.CookieDomainUrl
              );
            }
            resolve(res);
          },
          (msg) => {
            this.router.navigate(['/authentication-failed']);
            reject(msg);
          }
        );
    });
    return promise;
  }

  checkOESSAdminLogin(token) {
    let jwtHelper = new JwtHelperService();
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/master-login', token)
        .toPromise()
        .then(
          (res) => {
            this.globals.isLoading = true;
            let result = res;
            if (result && result['access_token']) {
              this.globals.authData = '';
             
              this.CookieService.delete(
                'expires_at',
                '/',
                this.globals.CookieDomainUrl
              );
              this.CookieService.delete(
                'access_token',
                '/',
                this.globals.CookieDomainUrl
              );
              this.CookieService.set(
                'access_token',
                result['access_token'],
                1,
                '/',
                this.globals.CookieDomainUrl
              );
              this.CookieService.set(
                'expires_at',
                window.btoa(result['expires_at']),
                1,
                '/',
                this.globals.CookieDomainUrl
              );
              this.CookieService.set(
                'token_type',
                window.btoa(result['token_type']),
                365,
                '/',
                this.globals.CookieDomainUrl
              );
            }
            resolve(res);
          },
          (msg) => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  getAuthUser() {
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(this.globals.baseAPIUrl + '/product-user')
        .toPromise()
        .then(
          (res) => {
            let result = res['data'];
           
            localStorage.setItem(
              'auth_user',
              window.btoa(JSON.stringify(result))
            );
            this.CookieService.set(
              'user_token',
              result['id'],
              1,
              '/',
              this.globals.CookieDomainUrl
            );

            this.CookieService.set('auth_user', window.btoa(JSON.stringify(result)), 365, '/', this.globals.CookieDomainUrl);
            this.globals.authData = result;
            setTimeout(() => {              
              resolve(result);
            }, 1000);
            //}
          },
          (msg) => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  getAuthUserInfo() {
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(this.globals.baseAPIUrl + '/user-info')
        .toPromise()
        .then(
          (res) => {
            resolve(res);
          },
          (msg) => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  logout() {
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(this.globals.baseAPIUrl + '/logout')
        .toPromise()
        .then(
          (res) => {
            this.globals.authData = '';
           
            localStorage.removeItem('auth_user');
            this.CookieService.delete('auth_user','/', this.globals.CookieDomainUrl);
            localStorage.removeItem('getUserPermission');
            this.CookieService.delete('selected_institute','/',this.globals.CookieDomainUrl);
            this.CookieService.delete(
              'expires_at',
              '/',
              this.globals.CookieDomainUrl
            );
            this.CookieService.delete(
              'access_token',
              '/',
              this.globals.CookieDomainUrl
            );
            this.CookieService.delete(
              'token_type',
              '/',
              this.globals.CookieDomainUrl
            );
            this.CookieService.delete(
              'institute_details',
              '/',
              this.globals.CookieDomainUrl
            );
            this.CookieService.delete(
              'user_token',
              '/',
              this.globals.CookieDomainUrl
            );
            this.globals.permissions = {};
            resolve(res);
          },
          (msg) => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  isLoggedIn() {
    let expires_at = window.atob(this.CookieService.get('expires_at'));
    let isExpired = expires_at ? false : true;
   
    if (isExpired) {
      this.globals.authData = undefined;
    }
    return !isExpired;

  }

  getIP() {
    let promise = new Promise((resolve, reject) => {
      this.http
        .get('https://api.ipify.org/?format=json')
        .toPromise()
        .then(
          (res) => {
            resolve(res);
          },
          (msg) => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  getLastUpdatedDateOfRolePermission() {
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(this.globals.baseAPIUrl + '/permission/latest-updated')
        .toPromise()
        .then(
          (res) => {
            resolve(res);
          },
          (msg) => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  getAllRolePermissionData(permission) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(this.globals.baseAPIUrl + '/permission/role-permission')
        .toPromise()
        .then(
          (res) => {
            let last_updated = res['data']['last_updated'];
            let permission_data = res['data']['permission_data'];
            localStorage.setItem(
              'last_updated_date_role_permission',
              window.btoa(last_updated)
            );
            localStorage.setItem(
              'all_role_permission',
              window.btoa(JSON.stringify(permission_data))
            );
            let active_license =
              this.globals.institute_details.active_license > 0 ? 0 : 0;

            let retrievedObject1 = permission_data.filter((item) => {
              if (
                item.role_value == this.globals.institute_details.role_value &&
                item.is_accessible_after_expire == active_license
              ) {
                return item;
              }
            })[0]?.permission_list;

            let user_role_permission = {};
            let index;
            this.globals.permissionText.forEach(function (menu, key) {
              index = retrievedObject1?.findIndex(
                (retrievedObject1) =>
                  retrievedObject1.slug === menu.key &&
                  retrievedObject1.has_access == 1
              );
              if (index != -1) {
                user_role_permission[menu.key] = true;
              } else {
                user_role_permission[menu.key] = false;
              }
            });
            localStorage.setItem(
              'user_role_permission',
              window.btoa(JSON.stringify(user_role_permission))
            );
            this.globals.permissions = user_role_permission;

            if (!this.globals.permissions[permission]) {
              this.router.navigate(['/permission-not-found']);
              return false;
            }

            resolve(res);
          },
          (msg) => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  getAllRolePermissionData1() {
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(this.globals.baseAPIUrl + '/permission/role-permission')
        .toPromise()
        .then(
          (res) => {
            let last_updated = res['data']['last_updated'];
            let permission_data = res['data']['permission_data'];
            localStorage.setItem(
              'last_updated_date_role_permission',
              window.btoa(last_updated)
            );
            localStorage.setItem(
              'all_role_permission',
              window.btoa(JSON.stringify(permission_data))
            );
            let active_license =
              this.globals.institute_details?.active_license > 0 ? 0 : 0;

            let retrievedObject1 = permission_data.filter((item) => {
              if (
                item.role_value == this.globals.institute_details?.role_value &&
                item.is_accessible_after_expire == active_license
              ) {
                return item;
              }
            })[0]?.permission_list;

            let user_role_permission = {};
            let index;
            this.globals.permissionText.forEach(function (menu, key) {
              index = retrievedObject1.findIndex(
                (retrievedObject1) =>
                  retrievedObject1.slug === menu.key &&
                  retrievedObject1.has_access == 1
              );
              if (index != -1) {
                user_role_permission[menu.key] = true;
              } else {
                user_role_permission[menu.key] = false;
              }
            });
            localStorage.setItem(
              'user_role_permission',
              window.btoa(JSON.stringify(user_role_permission))
            );
            this.globals.permissions = user_role_permission;

            resolve(res);
          },
          (msg) => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  convertDate(d) {
    var mm = d.getMonth() + 1,
      dd = d.getDate(),
      yyyy = d.getFullYear(),
      hour = d.getHours(),
      min = d.getMinutes(),
      sec = d.getSeconds();
    return (
      yyyy +
      '-' +
      (mm[1] ? mm : '0' + mm[0]) +
      '-' +
      (dd[1] ? dd : '0' + dd[0]) +
      'T' +
      hour +
      ':' +
      min +
      ':' +
      sec +
      'Z'
    );
  }

  getUserProductRoleList(data) {
    ;
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/user-details', data)
        .toPromise()
        .then(
          (res) => {
            resolve(res);
          },
          (msg) => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  getUserRoleList(data) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.globals.baseAPIUrl + '/user-details-auth', data)
        .toPromise()
        .then(
          (res) => {
            resolve(res);
          },
          (msg) => {
            reject(msg);
          }
        );
    });
    return promise;
  }
}
