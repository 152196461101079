<div class="inner_content_block">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <div class="title_block">
                    <h1 class="float-start">Domains</h1>
                    <a kendoButton routerLink="/{{this.globals.currentLink.split('/')[1]}}/configuration/domain/add" class="all_btn theme_btn float-end" *ngIf="globals.permissions['add-domain']">Add a
                        Domain</a>
                    <div class="clearfix"></div>
                </div>
                <div class="search_main_block">
                    <!-- <div class="filter_block">
                        <ul>
                            <li class="grey" *ngIf="globals.permissions['edit-domain']"><i class="fa fa-pencil"></i>Edit</li>
                            <li class="red" *ngIf="globals.permissions['delete-domain']"><i class="fa fa-trash"></i>Delete</li>
                        </ul>
                    </div> -->
                    <div class="clearfix"></div>
                    <kendo-grid [data]="domainList" [pageSize]="pageSize" [skip]="skip" [pageable]="true"
                        (pageChange)="pageChange($event)" [sort]="sort" [sortable]="{
                            allowUnsort: false,
                            mode: 'single'
                            }" (sortChange)="sortChange($event)" scrollable="none">
                        <kendo-grid-messages pagerItems="Domains" pagerItemsPerPage="Domains per page"
                            noRecords="No domains available.">
                        </kendo-grid-messages>
                        <ng-template kendoGridToolbarTemplate>
                            <input placeholder="Search by domain or description" kendoTextBox
                                (input)="onFilter($event.target.value)" />
                            <kendo-pager-page-sizes [pageSizes]="globals.pagesizes"></kendo-pager-page-sizes>
                        </ng-template>
                        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                            <kendo-pager-numeric-buttons [buttonCount]="globals.pageIndex">
                            </kendo-pager-numeric-buttons>
                            <kendo-pager-next-buttons></kendo-pager-next-buttons>
                            <kendo-pager-info></kendo-pager-info>
                        </ng-template>
                        <kendo-grid-column field="dom_provider_id" title="ID" [headerStyle]="{width: '5%','text-align': 'center'}"  [style]="{width: '5%'}" class="text-center" media="(min-width: 769px)">
                        </kendo-grid-column>
                        <kendo-grid-column field="name" title="Domain" [headerStyle]="{width: '25%'}"  [style]="{width: '25%'}" media="(min-width: 769px)">
                        </kendo-grid-column>
                        <kendo-grid-column field="description" title="Description" [headerStyle]="{width: '50%'}"  [style]="{width: '50%'}" media="(min-width: 769px)">
                             <ng-template kendoGridCellTemplate let-dataItem>
                                {{dataItem.description != "" ? dataItem.description : '-'}}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="is_active" title="Is Active?"[headerStyle]="{width: '10%','text-align': 'center'}" [style]="{width: '10%'}" [sortable]="false" class="text-center" *ngIf="globals.permissions['edit-domain']" media="(min-width: 769px)">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <!-- <kendo-switch (valueChange)="updateStatus(dataItem,rowIndex)" [(ngModel)]="dataItem.is_active" [checked]="dataItem.is_active==1" [onLabel]="'Yes'" [offLabel]="'No'"></kendo-switch> -->
                                {{dataItem.is_active==1 ? 'Yes' : 'No'}}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column title="Action" [headerStyle]="{width: '10%','text-align': 'center'}" [style]="{width: '10%'}" [sortable]="false" class="text-center" *ngIf="globals.permissions['edit-domain'] || globals.permissions['delete-domain']" media="(min-width: 769px)">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div kendoTooltip>
                                    <button  (click)="edit(dataItem.id)" title="Edit" class="icon_btn grey" *ngIf="globals.permissions['edit-domain']"><i
                                        class="fa fa-pencil"></i></button>
                                    <button (click)="delete(dataItem)" title="Delete" class="icon_btn red" *ngIf="globals.permissions['delete-domain']"><i
                                        class="fa fa-trash"></i></button>
                                </div>
                            </ng-template>
                        </kendo-grid-column>


                        <kendo-grid-column field="dom_provider_id" title="ID" [headerStyle]="{width: '60px','text-align': 'center'}"  [style]="{width: '60px'}" class="text-center" media="(max-width: 768px)">
                        </kendo-grid-column>
                        <kendo-grid-column field="name" title="Domain" [headerStyle]="{width: '300px'}"  [style]="{width: '300px'}" media="(max-width: 768px)">
                        </kendo-grid-column>
                       
                        <kendo-grid-column field="description" title="Description" [headerStyle]="{width: '300px'}"  [style]="{width: '300px'}" media="(max-width: 768px)">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                {{dataItem.description != "" ? dataItem.description : 'NA'}}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="is_active" title="Is Active?" [headerStyle]="{width: '100px','text-align': 'center'}"  [style]="{width: '100px'}" media="(max-width: 768px)" [sortable]="false" class="text-center" *ngIf="globals.permissions['edit-domain']">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <!-- <kendo-switch (valueChange)="updateStatus(dataItem,rowIndex)" [(ngModel)]="dataItem.is_active" [checked]="dataItem.is_active==1" [onLabel]="'Yes'" [offLabel]="'No'"></kendo-switch> -->
                                {{dataItem.is_active==1 ? 'Yes' : 'No'}}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column title="Action" [headerStyle]="{width: '100px','text-align': 'center'}"  [style]="{width: '100px'}" [sortable]="false" media="(max-width: 768px)" class="text-center" *ngIf="globals.permissions['edit-domain'] || globals.permissions['delete-domain']">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div kendoTooltip>
                                    <button (click)="edit(dataItem.id)" title="Edit"   class="icon_btn grey" *ngIf="globals.permissions['edit-domain']"><i
                                        class="fa fa-pencil"></i></button>
                                    <button (click)="delete(dataItem)" title="Delete"  class="icon_btn red" *ngIf="globals.permissions['delete-domain']"><i
                                        class="fa fa-trash"></i></button>
                                </div>
                            </ng-template>
                        </kendo-grid-column>

                       
                    </kendo-grid>
                </div>
            </div>
        </div>
    </div>
</div>