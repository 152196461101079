export const environment = {
  production: true,

  // DEV
  // baseUrl: 'https://admin.amsn.devbyopeneyes.com',
  // apiUrl: 'https://api.amsn.devbyopeneyes.com/public/api',
  // covidUrl: 'https://covid.amsn.devbyopeneyes.com',
  // dcmUrl: 'https://dcm.amsn.devbyopeneyes.com',
  // connectorUrl: 'https://connector.amsn.devbyopeneyes.com',
  // authUrl: 'https://admin.amsn.devbyopeneyes.com',
  // authUrlReal: 'https://myassessments.amsn.devbyopeneyes.com',
  // cndURL: 'https://assets.amsn.devbyopeneyes.com/',
  // CookieDomainUrl: 'amsn.devbyopeneyes.com',
  // TestMode: true,
  // env: 'prod'

  // // UAT
  // baseUrl: 'https://admin.amsn.uatbyopeneyes.com',
  // apiUrl: 'https://api.amsn.uatbyopeneyes.com/public/api',
  // covidUrl: 'https://covid.amsn.uatbyopeneyes.com',
  // dcmUrl: 'https://dcm.amsn.uatbyopeneyes.com',
  // connectorUrl: 'https://connector.amsn.uatbyopeneyes.com',
  // authUrl: 'https://admin.amsn.uatbyopeneyes.com',
  // authUrlReal: 'https://myassessments.amsn.uatbyopeneyes.com',
  // cndURL: 'https://assets.amsn.uatbyopeneyes.com/',
  // CookieDomainUrl: 'amsn.uatbyopeneyes.com',
  // TestMode: true,
  // env: 'prod',

  // UAT IFMA
  baseUrl: 'https://admin.ifma-sat.uatbyopeneyes.com',
  ssoLoginUrl: 'https://loginifmaorg-sandbox.azurewebsites.net/?redirectUrl=',
  ssoLogoutUrl: 'https://loginifmaorg-sandbox.azurewebsites.net/Account/LogOff/?redirectUrl=',
  apiUrl: 'https://api.ifma-sat.uatbyopeneyes.com/api',
  covidUrl: 'https://covid.ifma-sat.uatbyopeneyes.com',
  dcmUrl: 'https://sat.ifma-sat.uatbyopeneyes.com',
  connectorUrl: 'https://connector.ifma-sat.uatbyopeneyes.com',
  authUrl: 'https://mysat.ifma-sat.uatbyopeneyes.com',
  authUrlReal: 'https://myassessments.ifma-sat.uatbyopeneyes.com',
  cndURL: 'https://assets.ifma-sat.uatbyopeneyes.com/',
  CookieDomainUrl: 'ifma-sat.uatbyopeneyes.com',
  TestMode: true,
  env: 'prod',

  // AWS QA
  // baseUrl: 'https://admin.amsn.thisistheprogress.com',
  // apiUrl: 'https://api.amsn.thisistheprogress.com/public/api',
  // covidUrl: 'https://covid.amsn.thisistheprogress.com',
  // dcmUrl: 'https://dcm.amsn.thisistheprogress.com',
  // connectorUrl: 'https://connector.amsn.thisistheprogress.com',
  // authUrl: 'https://admin.amsn.thisistheprogress.com',
  // authUrlReal: 'https://myassessments.amsn.thisistheprogress.com',
  // cndURL: 'https://d2ielwr8km7l85.cloudfront.net/',
  // CookieDomainUrl: 'amsn.thisistheprogress.com',
  // TestMode: true,
  // env: 'prod',

  // AWS PreProd
  // baseUrl: 'https://manager.amsn.yourfinalversion.com',
  // apiUrl: 'https://api.amsn.yourfinalversion.com/public/api',
  // covidUrl: 'https://covid.amsn.yourfinalversion.com',
  // dcmUrl: 'https://dcm.amsn.yourfinalversion.com',
  // connectorUrl: 'https://connector.amsn.yourfinalversion.com',
  // authUrl: 'https://manager.amsn.yourfinalversion.com',
  // authUrlReal: 'https://myassessments.amsn.yourfinalversion.com',
  // cndURL: 'https://d3v7qksccoje42.cloudfront.net/preprod/',
  // CookieDomainUrl: 'amsn.yourfinalversion.com',
  // TestMode: false,
  // env: 'prod',

  // AWS Prod
  // baseUrl: 'https://manager.amsn.org',
  // apiUrl: 'https://myassessments-api.amsn.org/public/api',
  // covidUrl: 'https://covid.amsn.org',
  // dcmUrl: 'https://dcm.amsn.org',
  // connectorUrl: 'https://connector.amsn.org',
  // authUrl: 'https://manager.amsn.org',
  // authUrlReal: 'https://myassessments.amsn.org',
  // cndURL: 'https://d3v7qksccoje42.cloudfront.net/',
  // CookieDomainUrl: 'amsn.org',
  // TestMode: false,
  // env: 'prod',
};
