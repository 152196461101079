import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { Globals } from '../globals';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { InstituteUsersService } from '../services/institute-users.service';
import { ConfigurationService } from '../services/configuration.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
declare var Swal: any;
import { CookieService } from 'ngx-cookie-service';
declare var $, PerfectScrollbar, Swal: any;

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .hide-icon.k-dialog-wrapper .k-dialog-titlebar .k-dialog-actions {
        display: none;
      }
    `,
  ],
})
export class BreadcrumbsComponent implements OnInit {
  institute_id =
    this.globals.selected_institute != null
      ? this.globals.selected_institute.id
      : null;
  instituteList = [];
  instituteListFilterData = [];
  Recently_instituteList = [];
  OESS_Admin_present: boolean = false;
  AMSN_Admin_present: boolean = false;
  Individual_present: boolean = false;
  orgError: boolean = false;
  selected_institute =
    this.globals.selected_institute != null
      ? this.globals.selected_institute
      : null;
  btnDisabled = false;

  administrator_present: boolean = false;
  connector_present: boolean = false;
  covid_present: boolean = false;
  dcm_present: boolean = false;
  productList: any = [];
  highest_role_value = 0;
  org_dcm_present: boolean = false;

  constructor(
    public globals: Globals,
    public router: Router,
    private authService: AuthService,
    private CookieService: CookieService,
    private InstituteUsersService: InstituteUsersService,
    private ConfigurationService: ConfigurationService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {

    
    setTimeout(function () {
      new PerfectScrollbar('.switch_view_block');
    }, 500);
    this.getMyProductData();
    if (
      this.globals.institute_details.role_value == 2 ||
      this.globals.institute_details.role_value == 1 
    ) {
      this.getInstitutes();
      if (this.globals.Recently_institutes.length > 0) {
        for (let i = 0; i < this.globals.Recently_institutes.length; i++) {
          this.getInstituteDetailsById(this.globals.Recently_institutes[i], i);
        }
      }
    } else {
      let OESS_Admin_present = false;
      let AMSN_Admin_present = false;
      let Individual_present = false;
      let instituteList = [];
      let instituteList_rec = [];
    
      let minRoleValue = Number.MAX_VALUE;
      let minRoleInstitute = null;
      for (let i = 0; i < this.globals.authData.list.length; i++) {
        let s = this.globals.authData.list[i];
        if (s.institute != null && s.institute != '' && s.role_value < minRoleValue) {
          minRoleValue = s.role_value; 
          minRoleInstitute = s; 
        }
      }
      if (minRoleInstitute != null) {
        if (minRoleInstitute.role_value == 1) {
          OESS_Admin_present = true;
        } else if (minRoleInstitute.role_value == 2) {
          OESS_Admin_present = true;
        } else if (minRoleInstitute.role_value == 5) {
          Individual_present = true;
        }
        else{
          instituteList.push(minRoleInstitute);
          instituteList_rec.push(minRoleInstitute.institute.id);
        }
      }

      this.OESS_Admin_present = OESS_Admin_present;
      this.AMSN_Admin_present = AMSN_Admin_present;
      this.Individual_present = Individual_present;

      let instituteList1 = [];
      instituteList.forEach(function (obj) {
        let data = {
          id: obj.institute.id,
          institute_name: obj.institute.institute_name,
        };
        instituteList1.push(data);
      });
      instituteList1.unshift({
        id: null,
        institute_name: 'Select Organization',
        is_active: 1,
        provider_id: '',
      });
      this.instituteList = instituteList1;
      this.instituteListFilterData = instituteList1;

      let Recently_instituteList_ids = [];
      let Recently_institutes = this.globals.Recently_institutes;
      Recently_institutes.forEach(function (s) {
        let index = $.inArray(s, instituteList_rec);
        if (index !== -1) {
          Recently_instituteList_ids.push(s);
          instituteList_rec.splice(index, 1);
        }
      });
      let end = 2 - Recently_instituteList_ids.length;
      if (Recently_instituteList_ids.length < 2) {
        for (let i = 0; i < end; i++) {
          if (instituteList_rec.length > i) {
            Recently_instituteList_ids.push(instituteList_rec[i]);
          }
        }
      }
      if (Recently_instituteList_ids.length > 0) {
        for (let i = 0; i < Recently_instituteList_ids.length; i++) {
          this.getInstituteDetailsById(Recently_instituteList_ids[i], i);
        }
      }

      this.InstituteUsersService.getInstituteDetailsById(
        this.globals.institute_details.institute.id
      ).then(
        (data: any) => {
          this.selected_institute = data['data'][0];
          this.globals.selected_institute = this.selected_institute;

          this.CookieService.set(
            'selected_institute',
            window.btoa(JSON.stringify(this.selected_institute)),
            365,
            '/',
            this.globals.CookieDomainUrl
          );
          this.globals.saveRecentlyInstitutes(this.selected_institute.id);
          this.btnDisabled = false;
        },
        (error) => {
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        }
      );
    }


    // on body and html click close menu script
    $(document).ready(function () {
      $("body").click(function () {
      });
      $(".switch_button").click(function (event) {
        event.stopPropagation();
      });
      $(".switch_view_block").click(function (event) {
        event.stopPropagation();
      });
    });

  }

  getMyProductData() {
    let postdata = { user_id: this.globals.authData.id };
    this.authService.getUserRoleList(postdata).then(
      (data) => {
        let productList = data['data'];
        let connector_present = false;
        let covid_present = false;
        let dcm_present = false;
        let org_dcm_present = false;
        let administrator_present = false;

        this.productList = productList.filter((value) => {
          if (this.highest_role_value == 0) {
            this.highest_role_value = value.role_value;
          } else {
            if (value.role_value == 1) {
              this.highest_role_value = 1;
            } else if (value.role_value == 2 && this.highest_role_value != 1) {
              this.highest_role_value = 2;
            } else if (
              value.role_value == 6 &&
              this.highest_role_value != 1 &&
              this.highest_role_value != 2
            ) {
              this.highest_role_value = 6;
            } else if (
              value.role_value == 3 &&
              this.highest_role_value != 1 &&
              this.highest_role_value != 2 &&
              this.highest_role_value != 6
            ) {
              this.highest_role_value = 3;
            }
          }

          if (value.role_value == 1 || value.role_value == 2) {
            value.product_list.forEach(function (item) {
              if (item.product.value == 1) {
                connector_present = true;
              } else if (item.product.value == 2) {
                administrator_present = true;
              } else if (item.product.value == 3) {
                administrator_present = true;
              }
            });
          } else if (value.role_value == 5) {
            value.product_list.forEach(function (item) {
              if (item.product.value == 2) {
                covid_present = true;
              } else if (item.product.value == 3) {
                dcm_present = true;
              }
            });
          } else if (value.role_value == 4) {
            value.product_list.forEach(function (item) {
              if (item.product.value == 3) {
                org_dcm_present = true;
              }
            });
          } else if (value.role_value == 3 || value.role_value == 6) {
            value.product_list.forEach(function (item) {
              if (item.product.value == 1) {
                connector_present = true;
              } else if (item.product.value == 2) {
                administrator_present = true;
              } else if (item.product.value == 3) {
                administrator_present = true;
                org_dcm_present = true;
              }
            });
          }
          return value;
        });

        this.connector_present = connector_present;
        this.covid_present = covid_present;
        this.dcm_present = dcm_present;
        this.org_dcm_present = org_dcm_present;
        this.administrator_present = administrator_present;
        this.globals.isLoading = false;
      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
  }

  getInstitutes() {
    this.InstituteUsersService.getInstituteList().then(
      (data: any) => {
        data['data'].unshift({
          id: null,
          institute_name: 'Select Organization',
          is_active: 1,
          provider_id: '',
        });
        this.globals.isLoading = false;
        this.instituteList = data['data'];
        this.instituteListFilterData = data['data'];
      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
  }

  instituteFilter(value) {
    this.instituteList = this.instituteListFilterData.filter(
      (s) => s.institute_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  selectOrg() {
    if (this.institute_id != null) {
      this.orgError = false;
      this.CookieService.set(
        'selected_institute',
        window.btoa(JSON.stringify(this.selected_institute)),
        365,
        '/',
        this.globals.CookieDomainUrl
      );
      this.globals.saveRecentlyInstitutes(this.selected_institute.id);

      if (
        this.globals.institute_details.role_value != 2 &&
        this.globals.institute_details.role_value != 1 &&
        this.globals.institute_details.role_value != 6
      ) {
        let institute_details = this.globals.authData.list.filter(
          (s) => s.institute && s.institute.id == this.institute_id
        )[0];
        this.CookieService.set(
          'institute_details',
          window.btoa(JSON.stringify(institute_details)),
          365,
          '/',
          this.globals.CookieDomainUrl
        );
      }
      window.location.reload();
    } else {
      this.orgError = true;
    }
  }

  clearSelectedOrganization() {
    this.CookieService.delete(
      'selected_institute',
      '/',
      this.globals.CookieDomainUrl
    );
    this.globals.selected_institute = null;
    window.location.reload();
  }

  filterData(id) {
    this.institute_id = id.id;
    this.orgError = false;
    if (this.institute_id != null) {
      this.btnDisabled = true;
      this.InstituteUsersService.getInstituteDetailsById(
        this.institute_id
      ).then(
        (data: any) => {
          this.selected_institute = data['data'][0];
          this.btnDisabled = false;
        },
        (error) => {
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        }
      );
    } else {
      this.selected_institute = null;
      this.orgError = true;
    }
  }

  getInstituteDetailsById(institute_id, index) {
    this.InstituteUsersService.getInstituteDetailsById(institute_id).then(
      (data: any) => {
        if (data['data'][0]) {
          this.Recently_instituteList[index] = data['data'][0];
        }
      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
  }

  close() {
  
    this.globals.dialogOpened = false;
    if (
      this.globals.selected_institute == null &&
      (this.router.url == '/sat/configuration/subdomain-preference' ||
        this.router.url == '/sat/assessment/invite' ||
        this.router.url == '/sat/assessment/list' ||
        this.router.url == '/sat/assessment/list-result' ||
        this.globals.currentLink == '/sat/configuration/report-per-organization' )
    ) {
      window.history.back();
    } else if (
      this.globals.selected_institute == null &&
      (this.globals.currentLink == '/sat/assessment/list' ||
        this.globals.currentLink == '/sat/assessment/subscription-pack/list' ||
        this.globals.currentLink == '/sat/assessment/user/list'
      )
    ) {
      window.location.href = this.globals.baseUrl + this.globals.currentLink;
    }
  }

  switchOrg(selected_institute) {
    this.CookieService.set(
      'selected_institute',
      window.btoa(JSON.stringify(selected_institute)),
      365,
      '/',
      this.globals.CookieDomainUrl
    );
    this.globals.saveRecentlyInstitutes(selected_institute.id);

    if (
      this.globals.institute_details.role_value != 2 &&
      this.globals.institute_details.role_value != 1
    ) {
      let institute_details = this.globals.authData.list.filter(
        (s) => s.institute && s.institute.id == selected_institute.id
      )[0];
      this.CookieService.set(
        'institute_details',
        window.btoa(JSON.stringify(institute_details)),
        365,
        '/',
        this.globals.CookieDomainUrl
      );
    }
    window.location.reload();
  }

  switchRole(role_value) {
    let institute_details = this.globals.authData.list.filter(
      (s) => s.role_value == role_value
    )[0];
    this.CookieService.set(
      'institute_details',
      window.btoa(JSON.stringify(institute_details)),
      365,
      '/',
      this.globals.CookieDomainUrl
    );
    window.location.reload();
  }

  viewIndividualDCM() {
    let institute_details;
    institute_details = this.productList.filter((value) => {
      if (value.role_value == 5) {
        return value;
      }
    });

    let institute_details_new: any = {};
    institute_details_new.institute = institute_details[0].institute;
    institute_details_new.role_id = institute_details[0].role_id;
    institute_details_new.role_name = institute_details[0].role_name;
    institute_details_new.role_value = institute_details[0].role_value;
    institute_details_new.total_users = institute_details[0].total_users;
    institute_details_new.total_divisions =
      institute_details[0].total_divisions;
    institute_details_new.total_units = institute_details[0].total_units;

    this.CookieService.set(
      'institute_details',
      window.btoa(JSON.stringify(institute_details_new)),
      365,
      '/',
      this.globals.CookieDomainUrl
    );
    this.globals.institute_details = institute_details_new;
    this.CookieService.set(
      'current_product',
      window.btoa(JSON.stringify(3)),
      365,
      '/',
      this.globals.CookieDomainUrl
    );
    this.CookieService.set(
      'current_product_id',
      window.btoa('31c5fda4-3ec4-11eb-99e5-cdf45df6b9bb'),
      365,
      '/',
      this.globals.CookieDomainUrl
    );
    window.location.href = this.globals.dcmUrl;
  }

  viewOrgDCM() {
    let institute_details = this.productList.filter(
      (s) => s.role_value == this.highest_role_value
    )[0];
    this.CookieService.set(
      'institute_details',
      window.btoa(JSON.stringify(institute_details)),
      365,
      '/',
      this.globals.CookieDomainUrl
    );
    window.location.href = this.globals.dcmUrl;
  }

  registerOrg() {
    this.CookieService.delete(
      'institute_details',
      '/',
      this.globals.CookieDomainUrl
    );
    this.CookieService.delete(
      'current_product',
      '/',
      this.globals.CookieDomainUrl
    );
    this.CookieService.delete(
      'current_product_id',
      '/',
      this.globals.CookieDomainUrl
    );
    window.location.href =
      this.globals.connectorUrl + '/organization-registration';
  }

  select_view() {
    $('.switch_view_block').addClass('active_view');
  }

  select_view_close() {
    $('.switch_view_block').removeClass('active_view');
  }
}
